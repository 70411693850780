<template>
  <v-container
    :fluid="fluid"
    class="justify-center"
    :class="{ 'col-xl-8 offset-xl-2': !fluid }"
  >
    <v-card tile color="transparent" elevation="0">
      <slot></slot>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "k-page",

  props: {
    fluid: Boolean
  }
};
</script>

<style>
</style>
