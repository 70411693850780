<template>
  <KPage>
    <!--  -->
    <FilterBar />

    <!--  -->
    <v-card outlined class="overflow-hidden">
      <v-row>
        <v-col cols="12">
          <v-list class="pa-0 bordered">
            <v-list-item
              v-for="(contactType, key) in contact_types"
              :key="key"
              @click.stop="() => edit(contactType)"
            >
              <v-list-item-avatar>
                <v-icon>{{ contactType.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="contactType.name"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click.stop.prevent="() => remove(contactType)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog" />
    <DialogDelete ref="dialog_delete" />

    <!-- Botão FAB -->
    <FAB :visible="!loading" @click="add"></FAB>
    <!--  -->
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAvatar from "@/components/KAvatar";
import FAB from "@/components/FAB";
import DialogSave from "./DialogSave";
import DialogDelete from "./DialogDelete";
import FilterBar from "./FilterBar";

export default {
  components: {
    KPage,
    KForm,
    KAvatar,
    FAB,
    DialogSave,
    FilterBar,
    DialogDelete
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    contact_types() {
      return this.$store.getters["contact_types/all"];
    }
  },

  //
  methods: {
    /**
     * Abre o dialog como cadastro
     */
    add() {
      this.$refs["dialog"].openToAdd();
    },

    /**
     * Abre o dialog como edição
     */
    edit(data) {
      this.$refs["dialog"].openToEdit(data);
    },

    /**
     * Abre o dialog de exclusão
     */
    remove(data) {
      this.$refs["dialog_delete"].openToRemove(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("contact_types/all");
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>
